import React from 'react';
import PropTypes from 'prop-types'
import { Grid, Divider, Button, MenuItem } from '@mui/material'
import FieldGroup from '../fieldGroup'
import EditOrderItems from './editOrderItems'
import Summary from './summary'
import useTranslation from '../../hooks/useTranslation'

const EditOrder = props => {
  const { t } = useTranslation()
  return (
    <Grid container spacing={1} direction="row">
      <br />
      <Grid container item alignItems="center">
        <Grid item xs={12} md={6} lg={6} xl={6}>
          <h1>{props.order.id > 0 ? `${t('labels.edit_order')} #${props.order.id}` : t('labels.new_order')}</h1>
        </Grid>
        <Grid item xs={12} md={6} lg={6} xl={6}>
          <Button
            color="primary"
            sx={{ float: 'right' }}
            variant="contained"
            onClick={props.onSave}
            disabled={!props.editing}>
            {props.order.hasOwnProperty('id') ? (props.saving ? t('labels.saving') : t('labels.save')) : (props.saving ? t('labels.creating') : t('labels.create'))}
          </Button>
          <Button
            sx={{ marginRight: '2%', borderColor: 'lightgrey', float: 'right' }}
            variant="outlined"
            href={props.order.id > 0 ? `/admin/orders/${props.order.id}` : '/admin/orders'}>
            {t('labels.back')}
          </Button>
        </Grid>
      </Grid>
      <Divider width="100%" />
      <br />
      <Grid item xs={12} md={12} lg={12} xl={12}>
        <Button
          onClick={props.onOpenItemModal}
          style={{ float: 'right' }}
          variant="outlined">
          {t('labels.add_item')}
        </Button>
      </Grid>
      <Grid item xs={12} md={12} lg={12} xl={12}>
        {props.order.order_items && <EditOrderItems storeCurrency={props.storeCurrency} orderItems={props.order.order_items} onItemUpdate={props.onItemUpdate} order={{ ...props.order }} />}
      </Grid>
      <Grid item xs={12} md={12} lg={12} xl={12}>
        <Summary
          validationErrors={props.validationErrors}
          productFreight={props.productFreight}
          editMode={true}
          order={props.order}
          storeCurrency={props.storeCurrency}
          onChange={props.onChange}
          handleAddDiscount={props.handleAddDiscount}
          freeDelivery={props.freeDelivery}
          subtotal={props.subtotal} />
      </Grid>
      <Grid container item xs={12} md={12} lg={12} xl={12} spacing={2} alignItems="center">
        <Grid container item xs={12} md={12} lg={12} xl={12}>
          <Grid item xs={12} md={12} lg={12} xl={12}>
            <h2>{t('labels.customer_information')}</h2>
          </Grid>
          <Grid container item xs={12} md={12} lg={12} xl={12} spacing={2}>
            <Grid item xs={12} md={6} lg={6} xl={6}>
              <FieldGroup
                id="Email"
                type="email"
                label={t('labels.email')}
                name="email"
                value={props.order.email}
                validationState={props.validationErrors.hasOwnProperty('email') ? 'error' : null}
                onChange={props.onChange} />
            </Grid>
            <Grid item xs={12} md={6} lg={6} xl={6}>
              <FieldGroup
                id="phone"
                type="text"
                label={t('labels.phone')}
                name="phone"
                value={props.order.phone || ''}
                validationState={props.validationErrors.hasOwnProperty('phone') ? 'error' : null}
                onChange={props.onChange} />
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={2} alignItems="start" justifyContent="space-between">
          <Grid container item xs={12} md={6} lg={6} xl={6}>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <h3>{t('labels.delivery_address')}</h3>
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <FieldGroup
                id="shippingName"
                type="text"
                label={t('labels.shipping_name')}
                name="shipping_name"
                value={props.order.shipping_name || ''}
                validationState={props.validationErrors.hasOwnProperty('shipping_name') ? 'error' : null}
                onChange={props.onChange} />
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <FieldGroup
                id="shippingCompanyName"
                type="text"
                label={t('labels.shipping_company_name')}
                name="shipping_company_name"
                value={props.order.shipping_company_name || ''}
                validationState={props.validationErrors.hasOwnProperty('shipping_company_name') ? 'error' : null}
                onChange={props.onChange} />
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <FieldGroup
                id="shippingOrganisationNumber"
                type="text"
                label={t('labels.shipping_organisation_number')}
                name="shipping_organisation_number"
                value={props.order.shipping_organisation_number || ''}
                validationState={props.validationErrors.hasOwnProperty('shipping_organisation_number') ? 'error' : null}
                onChange={props.onChange} />
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <FieldGroup
                id="shippingAddress"
                type="text"
                label={t('labels.address_line_1')}
                name="shipping_address_one"
                value={props.order.shipping_address_one || ''}
                validationState={props.validationErrors.hasOwnProperty('shipping_address_one') ? 'error' : null}
                onChange={props.onChange} />
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <FieldGroup
                id="shippingAddressTwo"
                type="text"
                label={t('labels.address_line_2')}
                name="shipping_address_two"
                value={props.order.shipping_address_two || ''}
                validationState={props.validationErrors.hasOwnProperty('shipping_address_two') ? 'error' : null}
                onChange={props.onChange} />
            </Grid>
            <Grid item container xs={12} spacing={1}>
              <Grid item xs={6}>
                <FieldGroup
                  id="shippingPostcode"
                  type="text"
                  label={t('labels.postcode')}
                  name="shipping_postcode"
                  value={props.order.shipping_postcode || ''}
                  validationState={props.validationErrors.hasOwnProperty('shipping_postcode') ? 'error' : null}
                  onChange={props.onChange} />
              </Grid>
              <Grid item xs={6}>
                <FieldGroup
                  id="shippingCity"
                  type="text"
                  label={t('labels.city')}
                  name="shipping_city"
                  value={props.order.shipping_city || ''}
                  validationState={props.validationErrors.hasOwnProperty('shipping_city') ? 'error' : null}
                  onChange={props.onChange} />
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <FieldGroup
                name="shipping_country"
                value={props.order.shipping_country ?? ''}
                label={t('labels.country')}
                onChange={props.updateShippingCountry}
                variant="outlined"
                error={props.validationErrors.hasOwnProperty('invoice_country')}
                select
                fullWidth>
                <MenuItem value=''><em>{t('labels.select_country')}</em></MenuItem>
                {props.countryOptions.map((country, index) => (
                  <MenuItem key={index} value={country.label}>{country.label}</MenuItem>
                ))}
              </FieldGroup>
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <FieldGroup
                id="shippingInstructions"
                type="text"
                label={t('labels.shipping_instructions')}
                name="delivery_instructions"
                value={props.order.delivery_instructions || ''}
                multiline
                rows={4}
                validationState={props.validationErrors.hasOwnProperty('delivery_instructions') ? 'error' : null}
                onChange={props.onChange} />
            </Grid>
          </Grid>
          <Grid container item xs={12} md={6} lg={6} xl={6}>
            <Grid>
              <h3>{t('labels.invoice_address')}</h3>
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <FieldGroup
                id="invoiceName"
                type="text"
                label={t('labels.invoice_name')}
                name="invoice_name"
                value={props.order.invoice_name || ''}
                validationState={props.validationErrors.hasOwnProperty('invoice_name') ? 'error' : null}
                onChange={props.onChange} />
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <FieldGroup
                id="invoiceEmail"
                type="email"
                label={t('labels.invoice_email')}
                name="invoice_email"
                value={props.order.invoice_email || ''}
                validationState={props.validationErrors.hasOwnProperty('invoice_email') ? 'error' : null}
                onChange={props.onChange} />
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <FieldGroup
                id="InvoiceCompanyName"
                type="text"
                label={t('labels.invoice_company_name')}
                name="invoice_company_name"
                value={props.order.invoice_company_name || ''}
                validationState={props.validationErrors.hasOwnProperty('invoice_company_name') ? 'error' : null}
                onChange={props.onChange} />
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <FieldGroup
                id="InvoiceOrganisationNumber"
                type="text"
                label={t('labels.invoice_organisation_number')}
                name="invoice_organisation_number"
                value={props.order.invoice_organisation_number || ''}
                validationState={props.validationErrors.hasOwnProperty('invoice_organisation_number') ? 'error' : null}
                onChange={props.onChange} />
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <FieldGroup
                id="invoiceAddress"
                type="text"
                label={t('labels.address_line_1')}
                name="invoice_address_one"
                value={props.order.invoice_address_one || ''}
                validationState={props.validationErrors.hasOwnProperty('invoice_address_one') ? 'error' : null}
                onChange={props.onChange} />
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <FieldGroup
                id="invoiceAddressTwo"
                type="text"
                label={t('labels.address_line_2')}
                name="invoice_address_two"
                value={props.order.invoice_address_two || ''}
                validationState={props.validationErrors.hasOwnProperty('invoice_address_two') ? 'error' : null}
                onChange={props.onChange} />
            </Grid>
            <Grid container item xs={12} spacing={1}>
              <Grid item xs={6}>
                <FieldGroup
                  id="invoicePostcode"
                  type="text"
                  label={t('labels.postcode')}
                  name="invoice_postcode"
                  value={props.order.invoice_postcode || ''}
                  validationState={props.validationErrors.hasOwnProperty('invoice_postcode') ? 'error' : null}
                  onChange={props.onChange} />
              </Grid>
              <Grid item xs={6}>
                <FieldGroup
                  id="invoiceCity"
                  type="text"
                  label={t('labels.city')}
                  name="invoice_city"
                  value={props.order.invoice_city || ''}
                  validationState={props.validationErrors.hasOwnProperty('invoice_city') ? 'error' : null}
                  onChange={props.onChange} />
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <FieldGroup
                name="invoice_country"
                value={props.order.invoice_country || ''}
                label={t('labels.country')}
                onChange={props.onChange}
                variant="outlined"
                error={props.validationErrors.hasOwnProperty('invoice_country')}
                select
                fullWidth>
                <MenuItem value=''><em>{t('labels.select_country')}</em></MenuItem>
                {props.countryOptions.map((country, index) => (
                  <MenuItem key={index} value={country.label}>{country.label}</MenuItem>
                ))}
              </FieldGroup>
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <FieldGroup
                id="invoiceInstructions"
                type="text"
                label={t('labels.invoice_instructions')}
                name="invoice_instructions"
                value={props.order.invoice_instructions || ''}
                multiline
                rows={4}
                validationState={props.validationErrors.hasOwnProperty('invoice_instructions') ? 'error' : null}
                onChange={props.onChange} />
            </Grid>
          </Grid>
        </Grid>  
      </Grid>
      <Divider width='100%' sx={{ paddingTop: '2%' }} />
      <Grid item xs={12} md={12} lg={12} xl={12}>
        <Button
          color="primary"
          disabled={!props.editing}
          variant="contained"
          onClick={props.onSave}>
          {props.order.hasOwnProperty('id') ? (props.saving ? t('labels.saving') : t('labels.save')) : (props.saving ? t('labels.creating') : t('labels.create'))}
        </Button>
      </Grid>
    </Grid>
  );
}

EditOrder.propTypes = {
  order: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onContentChange: PropTypes.func.isRequired,
  updateShippingCountry: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onItemUpdate: PropTypes.func.isRequired,
  handleAddDiscount: PropTypes.func.isRequired,
  onOpenItemModal: PropTypes.func.isRequired,
  validationErrors: PropTypes.object,
  editing: PropTypes.bool,
  countryOptions: PropTypes.array.isRequired,
  storeCurrency: PropTypes.object.isRequired,
  subtotal: PropTypes.number.isRequired,
  productFreight: PropTypes.number.isRequired,
  saving: PropTypes.bool,
  freeDelivery: PropTypes.bool
};

export default EditOrder;